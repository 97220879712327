import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";

import logo from "../../images/kc-logo.png";
import whatsappIcon from "images/whatsapp.png";
import googleMapsIcon from "images/google-maps.png";

const Container = tw.div`relative bg-gray-200 text-gray-700 -mb-8 -mx-8 px-8 py-20 lg:py-24`;
const Content = tw.div`max-w-screen-xl mx-auto relative z-10`;
const SixColumns = tw.div`flex flex-wrap text-center sm:text-left justify-center sm:justify-start md:justify-between -mt-12`;

const Column = tw.div`px-4 sm:px-0 sm:w-1/4 md:w-auto mt-12`;

const ColumnHeading = tw.h5`uppercase font-bold`;

const LinkList = tw.ul`mt-6 text-sm font-medium`;
const LinkListItem = tw.li`mt-3`;

const Divider = tw.div`my-16 border-b-2 border-gray-300 w-full`;

const ThreeColRow = tw.div`flex flex-col md:flex-row items-center justify-between`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-8`;
const LogoText = tw.h5`ml-2 text-xl font-black tracking-wider text-gray-800`;

const CopywrightNotice = tw.p`text-center text-sm sm:text-base mt-8 md:mt-0 font-medium text-gray-500`;

export default ({id=""}) => {
  return (
    <Container>
      <a id={id}>
      <Content>
        <SixColumns>
          <Column>
            <ColumnHeading>Location</ColumnHeading>
              <iframe title = "location" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3423.8682811607023!2d75.8304544758903!3d30.89034557450862!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391a83e536c35e9f%3A0xaa3b5d1712b19247!2sKaushal%20Clinic!5e0!3m2!1sen!2sca!4v1691360848822!5m2!1sen!2sca" width="400" height="300" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </Column>
          <Column>
            <ColumnHeading>Hours</ColumnHeading>
              <ul>
                <li>Monday: 4:00 PM - 8:00 PM</li>
                <li>Tuesday: 4:00 PM - 8:00 PM</li>
                <li>Wednesday: 4:00 PM - 8:00 PM</li>
                <li>Thursday: 4:00 PM - 8:00 PM</li>
                <li>Friday: 4:00 PM - 8:00 PM</li>
                <li>Saturday: 4:00 PM - 8:00 PM</li>
                <li>Sunday: Closed (Only by Appointment) </li>
              </ul>
          </Column>
          <Column>
            <ColumnHeading>Contact</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <a href="tel:+919815662600"><PrimaryButtonBase>+91-9815662600</PrimaryButtonBase></a>
              </LinkListItem>
              <br></br>
              <ThreeColRow>
                <Column>
                <a href="https://wa.me/+919815662600"><img src={whatsappIcon} alt="Whatsapp" style={{width: "3rem", height: "3rem"}}></img></a>
                </Column>
                <Column>
                <a href="https://goo.gl/maps/XAaY2LnbDShaKA3B8"><img src={googleMapsIcon} alt="google maps" style={{width: "3rem", height: "3rem"}}></img></a>
                </Column>
              </ThreeColRow>
            </LinkList>
          </Column>
        </SixColumns>
        <Divider />
        <ThreeColRow>
          <LogoContainer>
            <LogoImg src={logo} />
            <LogoText>Kaushal Clinic</LogoText>
          </LogoContainer>
          <CopywrightNotice>&copy; Owned and operated by Dr. Seema Kaushal. </CopywrightNotice>
        </ThreeColRow>
      </Content>
      </a>
    </Container>
  );
};
