import React from "react"
import { Routes, Route } from 'react-router-dom';
import "style.css"
import "tailwindcss/lib/css/preflight.css"
import AnimationRevealPage from "helpers/AnimationRevealPage"
import LandingPage from "components/Pages/LandingPage"

function App() {
  return (
    <AnimationRevealPage>
      <Routes>
        <Route path="/" element={<LandingPage />} />
      </Routes>
    </AnimationRevealPage>
  )
}

export default App