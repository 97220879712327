import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithVideo.js";
import Features from "components/features/ThreeColSimple.js";
import MainFeature from "components/features/TwoColWithButton.js";
import Testimonial from "components/testimonials/ThreeColumnWithProfileImage.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";

import infertility from "images/infertility.png";
import maternity from "images/maternity.png";
import gynecology from "images/gynecology.png";
import seema from "images/seema-welcome.jpeg"
import sthetoscope from "images/sthetoscope.png"
import teenager from "images/teenager.png"

export default () => {
  const Subheading = tw.span`tracking-wider text-sm font-bold text-black`;
  const HighlightedText = tw.span`text-fuchsia-400 px-4 transform -skew-x-12 inline-block`;
  const Description = tw.span`inline-block mt-8`;
  const imageCss = tw`rounded-4xl`;
  return (
    <AnimationRevealPage>
      <Hero
        heading={<>Namaste 🙏<HighlightedText>You are our patient, client and guest.</HighlightedText></>}
        description="Welcome to our premier gynaecologist clinic in Ludhiana, dedicated to empowering women's health and providing compassionate care. Discover expert gynaecological services tailored to your needs, ensuring optimal well-being and peace of mind."
        imageSrc={seema}
      />
      <MainFeature
        subheading={<Subheading>Established Since 2008</Subheading>}
        heading={
          <>
            We've been serving patients for
            <wbr /> <HighlightedText>over 15 years.</HighlightedText>
          </>
        }
        description={
          <Description>
            Discover exceptional women's healthcare at our well-established medical clinic in Ludhiana, proudly specializing in gynaecology for over 15 years. With a dedicated team of experienced professionals, we prioritize your comfort and health, offering personalized and compassionate care. From routine check-ups to advanced treatments, our clinic provides a warm and supportive environment where you can feel confident in receiving the highest standard of gynaecological services. Your well-being is our priority, and we are committed to empowering women with the knowledge and resources they need to make informed decisions about their health. Trust in our expertise as we guide you through every step of your journey towards optimal health and happiness.
          </Description>
        }
        buttonRounded={false}
        textOnLeft={false}
        primaryButtonText=""
        imageSrc={sthetoscope}
        imageCss={imageCss}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 -translate-x-1/2 md:w-32 md:h-32 opacity-25`}
      />
      <Features
        heading={
          <>
            Amazing <HighlightedText>Services.</HighlightedText>
          </>
        }
        cards={[
          {
            imageSrc: gynecology,
            title: "Gynaec Consultation",
            description: "Expert gynaecological consultation tailored to your unique needs and well-being.",
            url: null
          },
          {
            imageSrc: infertility,
            title: "Infertility Consultation",
            description: "Compassionate guidance and personalized solutions for overcoming infertility, provided by experienced fertility experts during infertility consultations.",
            url: null
          },
          {
            imageSrc: maternity,
            title: "Family Planning",
            description: "Empowering individuals with comprehensive family planning options to make informed decisions about their reproductive health.",
            url: null
          },
          {
            imageSrc: teenager,
            title: "Adolescent Educator",
            description: "Nurturing and guiding young minds through the dynamic phase of adolescence.",
            url: null
          },
        ]}

        imageContainerCss={tw`p-2!`}
        imageCss={tw`w-20! h-20!`}
        id="services"
      />
      {/* <Testimonial
        subheading=""
        heading={<>Customers <HighlightedText>Love Us.</HighlightedText></>}
      /> */}
      <Footer id="footer"/>
    </AnimationRevealPage>
  );
}
